<template>
  <v-dialog v-model="active" width="500">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="right-button" dark>Total Cost: {{ dollarFormat(grandTotal) }}</v-btn>
    </template>
    <v-card>
      <v-card-title>M.Ed. Registration Pricing</v-card-title>
      <v-card-text>
        <p>You are currently signed up to take {{ courseCount }} coures{{ onlineCourse ? ' (including one online course)' : '' }}, totaling {{ courseCount * 3 }} credit hours.</p>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Tuition</v-list-item-title>
              <v-list-item-subtitle>${{ tuition }} per credit hour</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title>{{ dollarFormat(tuitionTotal) }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Materials</v-list-item-title>
              <v-list-item-subtitle>{{ dollarFormat(materials) }} per course</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title>{{ dollarFormat(materialsTotal) }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="graduating">
            <v-list-item-content>
              <v-list-item-title>Graduation Fee</v-list-item-title>
              <v-list-item-subtitle>{{ dollarFormat(gradFee) }} single fee</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title>{{ dollarFormat(gradFee) }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Student Activity Fee</v-list-item-title>
              <v-list-item-subtitle>{{ dollarFormat(actFee) }} per course{{ onlineCourse ? ' (excludes online course)' : '' }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title>{{ dollarFormat(actFeeTotal) }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Technology Fee</v-list-item-title>
              <v-list-item-subtitle>{{ dollarFormat(techFee) }} per course</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title>{{ dollarFormat(techFeeTotal) }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title style="font-weight:bold">Total Tuition &amp; Fees{{ housingWeeks > 0 ? '*' : '' }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title style="font-weight:bold">{{ dollarFormat(subtotal) }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          <template v-if="housingWeeks > 0">
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Housing ({{ housingChoice + (housingChoice === '3-person suite' ? ', ' + suiteChoice + ' room' : '') }})</v-list-item-title>
                <v-list-item-subtitle>{{ dollarFormat(housingFee) }} per week</v-list-item-subtitle>
                <v-list-item-subtitle>For {{ housingWeeks }} weeks</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-title>{{ dollarFormat(housingFee * housingWeeks) }}</v-list-item-title>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
            <v-list-item-content>
              <v-list-item-title style="font-weight:bold">Grand Total</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title style="font-weight:bold">{{ dollarFormat(grandTotal) }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          </template>
        </v-list>
        <p v-if="housingWeeks > 0">Financial Aid can only be applied to the Tuition &amp; Fees and cannot be applied to housing.</p>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
.right-button {
  position: fixed;
  bottom: 10%;
  right: 0px;
  background-color: #193264 !important;
}
.v-input.no-line > .v-input__control > .v-input__slot:before {
  border-width: 0 !important;
}
</style>
<script>
import { computed, ref } from '@vue/composition-api'
import { dollarFormat } from '../../helpers/formatters'

export default {
  props: {
    floating: {
      type: Boolean,
      default: true
    },
    courseCount: {
      type: Number,
      default: 0
    },
    onlineCourse: {
      type: Boolean,
      default: false
    },
    capstoneCourse: {
      type: Boolean,
      default: false
    },
    graduating: {
      type: Boolean,
      default: false
    },
    housingWeeks: {
      type: Number,
      default: 0
    },
    housingChoice: {
      type: String,
      default: ''
    },
    suiteChoice: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const active = ref(false)

    const tuition = ref(500)
    const tuitionTotal = computed(() => props.courseCount * 3 * tuition.value)

    const materials = ref(16)
    const materialsTotal = computed(() => (props.courseCount - (props.capstoneCourse ? 1 : 0)) * materials.value)
    const actFee = ref(36)
    const actFeeTotal = computed(() => (props.courseCount - (props.onlineCourse ? 1 : 0) - (props.capstoneCourse ? 1 : 0)) * actFee.value)
    const techFee = ref(28)
    const techFeeTotal = computed(() => (props.courseCount - (props.capstoneCourse ? 1 : 0)) * techFee.value)
    const gradFee = ref(160)
    const subtotal = computed(() => tuitionTotal.value + materialsTotal.value + actFeeTotal.value + techFeeTotal.value + (props.graduating ? gradFee.value : 0))
    const housingFee = computed(() => {
      switch (props.housingChoice) {
        case '1-person suite':
          return 366
        case '2-person suite':
          return 261
        case '3-person suite':
          if (props.suiteChoice === '1-person') return 202
          else if (props.suiteChoice === '2-person') return 149
          break
        case '4-person suite':
          return 150
      }
      return 0
    })

    const grandTotal = computed(() => subtotal.value + (props.housingWeeks * housingFee.value))

    return {
      active,
      tuition,
      tuitionTotal,
      materials,
      materialsTotal,
      actFee,
      actFeeTotal,
      techFee,
      techFeeTotal,
      gradFee,
      subtotal,
      housingFee,
      grandTotal,
      dollarFormat
    }
  }
}
</script>
